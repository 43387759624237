body {
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

html,
body,
#app,
#app > div,
#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

html,
body,
#root {
  min-height: 100%;
  max-width: 100%;
  width: 100%;
  height: 100%;
  background-color: #f5f6fa;
}

:root {
  --darkGrey: #4f5363;
  --midGrey: #878b9b;
  --offWhite: #f5f5f5;
  --veryLightGrey: #e1e3ea;
  --white60: rgba(255, 255, 255, 0.6);
  --primary: #191e31;
  --blue: #388df9;
  --orange: #ff9951;
  --green: #05c26c;
  --purple: #7117ff;
  --accent: #fec833;
  --red: #eb4d56;
  --light-grey: #cbced9;
  --dark-grey: #4f5363;
  --very-light-grey: #e1e3ea;
  --mid-grey: #878b9b;
  --off-white: #f5f5f5;
  --white: #ffffff;
  --black: #000000;
  --white-one: #e8e8e8;
  --white-two: #f8f8f8;
  --confirm-green: green;
}
