.Login {
  background-color: var(--primary);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .Login-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    &-logo {
      color: white;
      font-family: 'AvenirLTStd-Medium', sans-serif;
      display: flex;
      align-items: center;
      font-size: 24px;
      letter-spacing: 2px;

      svg {
        width: 100%;
        max-width: 176px;
      }
    }

    &-name {
      font-size: 15px;
      font-weight: bold;
      line-height: 1.33;
      color: var(--accent);
    }
  }

  .Login-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .Login-prompt {
    background-color: white;
    padding: 2rem;
    color: var(--primary);
    width: 592px;

    &-title {
      font-size: 23px;
      font-weight: bold;
      line-height: 1.39;
      margin-bottom: 0.25rem;
    }

    &-description {
      font-size: 15px;
      line-height: 1.33;
      margin-bottom: 48px;
    }

    &-actions {
      display: flex;
      justify-content: center;
    }

    &-button {
      flex: 1;
      border: 0;
      background-color: var(--offWhite);
      padding: 1rem;
      display: flex;
      justify-content: center;
      font-size: 15px;
      font-weight: bold;
      line-height: 1.33;
      align-items: center;
      color: var(--primary);
      cursor: pointer;

      svg {
        height: 30px;
        margin-right: 0.8rem;
      }
    }
  }
}
